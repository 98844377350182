import React from 'react'
import './scss/index.scss'
import translate from '../../i18n'

export default function ContactForm({ currentLanguage }) {
  return (
    <form
      method="post"
      action="/success/"
      name="contact_form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      className="contact_form"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="input_container">
        <label htmlFor="name">
          {translate('form_label_name', currentLanguage)}
        </label>
        <input type="text" name="name" id="name" />
      </div>
      <div className="input_container">
        <label htmlFor="email">
          {translate('form_label_email', currentLanguage)}
        </label>
        <input type="text" name="email" id="email" />
      </div>
      <div className="input_container">
        <label htmlFor="message">
          {translate('form_label_message', currentLanguage)}
        </label>
        <textarea name="message" id="message" rows="6" />
      </div>
      <ul className="actions">
        <li>
          <input
            type="submit"
            value={translate('form_button_send')}
            className="special"
          />
        </li>
      </ul>
    </form>
  )
}
