import React from 'react'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'
import ContactForm from '../components/ContactForm'

class Contact extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={'Meistä - Ota yhteyttä'}>
        <HeaderMeta
          title="Ta kontroll över din privatekonomi men hjälp av Bluemonday.se"
          description="Luotettavat nettikasinot, ajankohtaiset peliarvostelut, bonukset ja yleistä tietoutta nettikasinolla pelaamisesta... Tätä kaikkea tarjoaa bluemonday!"
        />
        <ProgressiveBgImage
          hqImage="/images/bluemonday-bg.jpg"
          lqImage="/images/bluemonday-bg-lq.jpg"
        >
          <section className="section content-box dark">
            <ContactForm currentLanguage="sv" />
          </section>
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default Contact
